@import '~antd/dist/antd.less';

// override antd theme
@input-height-lg: 44px;
@checkbox-border-radius: 2px;

.ant-layout {
  background-color: #fafafa !important;
}

.btnGreen {
  background-color: #52c41a;
  border-color: #52c41a;

  &.ant-btn-primary {
    background-color: #52c41a;
    border-color: #52c41a;
  }

  &.ant-btn-primary:hover,
  .ant-btn-primary:focus {
    color: #fff;
    border-color: #73d13d !important;
    background: #73d13d !important;
  }

  &.ant-btn-primary:active {
    color: #fff;
    border-color: #389e0d !important;
    background: #389e0d !important;
  }
}

.ant-descriptions-row > th, .ant-descriptions-row > td {
  padding-bottom: 24px;
}
@primary-color: #1b91fe;@link-color: #1b91fe;